<template>
  <div class="GraphicDetails">
    <div class="title">
      <h3>{{content.title}}</h3>
      <p>
        {{content.create_time}}
        <span>
          <img :src="content.media_logo"
               alt="">
          <strong>{{content.media_name}}</strong>
        </span>
      </p>

    </div>
    <div class="content"
         v-html="content.content">

    </div>
  </div>
</template>

<script>
import {
  getNewsDetails,
} from "@/api/index";
export default {
  data () {
    return {
      content: ''
    }
  },
  watch: {
    $route: {
      handler () {
        this.initTbel();
      }
    }
  },
  created () {
    this.initTbel();
  },
  methods: {
    initTbel () {
      let datas = {
        newsId: this.$route.query.id,
      }
      getNewsDetails(datas).then(res => {
        this.content = res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.GraphicDetails {
  background: #fff;
  padding: 10px;
}
.title {
  h3 {
    text-align: left;
    font-size: 20px;
    padding: 0 0 0 4px;
    margin: 17px 0 0 0;
  }
  p {
    text-align: left;
    font-size: 12px;
    padding: 15px 0 0 6px;
    margin: 0;
    color: #a7a7a7;
  }
  span {
    padding-left: 11px;
  }
  img {
    height: 14px;
    position: relative;
    top: 2px;
  }
  strong {
    padding-left: 5px;
    font-weight: normal;
  }
}
.content {
  padding-top: 30px;
  width: 100%;
  word-wrap: break-word;
  text-align: left;
}
</style>
